.App-container {
  /* background-image: url("./images/pastelbackground3.png"); */
  background-size: cover;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Roboto', sans-serif;
  background: #181c2c;
}



.my-body {
  /* background-image: url('../images/praxisbackground2.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
}

.my-habit {
  padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto
}

#loginResult {
  font-size: 15px;
  color: darkred;
}

.my-container {
  height: 530px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 #202434, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* backdrop-filter: blur(10px); */
  background-color: #202434; 
  border: 1px solid #00CC8E;


}

.dash-container {
  box-shadow: 0 4px 8px 0 #202434, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: 1;
}

.dashBox {
  /* background-color: #baa1a7; */
  
  /* box-shadow: 0 4px 8px 0 #202434, 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border-radius: 5px;
  color: white;
  position: relative;
  
  /* border: 1px solid black; */
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  
  /* backdrop-filter: blur(10px); */
  background-color: #202434; 
  box-shadow: 20px 20px 10px 0px rgba(0, 0, 0, 0.125);



}

.row>*{
  padding-right: calc(1.5rem * .5) !important;
  padding-left: calc(1.5rem * .5) !important;
}
#habitBox {
  width: 98.5%;
  position: relative;
  height: 55vh;
 
}

#habitPageBox {
  width: 100%;
  display: flex;
  height: 110vh;
  justify-content: center;
  align-items: center; 
  flex-direction: column; 
}

.frostedBackground {
  filter: blur(4px);
  position: absolute;
  width: 100%;
  height: 100vh;
}

#progressBox {
  width: 100%;
  height: 15.7vh;
  margin-bottom: 5vh
}

.modal-content {
  border-radius: 5px !important;
  height: 60vh !important;
  width: 50vw !important;
}

.modal-footer, .modal-header {
  border: 0px solid transparent !important;
}


@media only screen and (max-width: 825px) {
  .modal-content {
    border-radius: 5px !important;
    height: 100vh !important;
    width: 100vw !important;
    
  }
}

.modal {
  overflow: auto;
  right: 0 !important;

}

/* .modal-open {
  overflow: auto;
} */

.modal-open[style] {
  padding: 0px !important;
}

#motiBox {
  width: 100%;
  height: 55vh;
  position:relative;
}

#calBox {
  margin-top: 5vh;
  width: 95.5%;
  margin-left: 2%;
  display: flex;
  justify-content: center;
  
  flex-direction: column;
}

#calBoxInner {

  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
}

.my-row {
  height: 7.4vh;
  max-width: 55.55vh;
 
}

.lesspadding {
  padding: 4 !important;
  margin: 0 !important;
}

.my-row2 {
  height: 45px;
  max-width: 600px;
}

.my-row3 {
  border-bottom: 3px solid #dbabbe;
}
.my-col {
  align-items: center;
  padding: 0px;
}

.my-col2 {
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;

  background-size: 100%, 100%;
  border-radius: 50%;

  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: black;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #797b84;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  margin-top: 43px;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  border-right: 3px solid #dbabbe;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

#navOption1,
#navOption2,
#navOption3,
#navOption4,
#lOut,
#flatNav {
  cursor: pointer;
}

#navOption1:hover,
#navOption2:hover,
#navOption3:hover,
#navOption4:hover,
#lOut:hover,
#flatNav:hover {
  color: white;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.closebtn:hover {
  color: white;
  cursor: pointer;
}

/* on hover for inactive Habits */


.mytools {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

 .mytools .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.mytools:hover .tooltiptext {
  visibility: visible;
}

.noSelect {
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none;
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 

}